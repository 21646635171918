export const baseUrl = process.env.REACT_APP_API_URL

export const API_USER_SIGN_IN = '/auth/login'
export const API_USER_FORGET_PASSWORD = '/auth/forgetPassword'
export const API_USER_CHECK_PASSPORT = '/auth/checkPassport/:selector/:token'
export const API_USER_RESET_PASSWORD = '/auth/resetPassword/:selector/:token'
export const API_USER_ACCOUNT_EXECUTIVES = '/auth/getAccountExecutives'
export const API_USER_REGISTER = '/auth/register'

export const API_USER_PROFILE = '/user/profile'

export const API_DOCS_DOWNLOAD = '/storage/download'
export const API_DOCS_MULTI_DOWNLOAD = '/storage/multiDownload'
export const API_DOCS_UPLOAD = '/storage/upload'

export const API_HOME_NEWSLETTER = '/home/newsletter'
export const API_HOME_SUBMIT_CONTACTUS = '/home/submitContactUs'

export const API_DOCUMENT = '/document'
export const API_DOCUMENT_PATCH = '/document'
export const API_DOCUMENT_ITEM = '/document/:id'

export const API_PLACEHOLDER = '/admin/placeholder'
export const API_PLACEHOLDER_ITEM = '/admin/placeholder/:id'

export const API_PREPAYMENT_TYPE = '/admin/prepaymentType'
export const API_PREPAYMENT_TYPE_ITEM = '/admin/prepaymentType/:id'

export const API_STATE_GUARANTY = '/admin/stateGuaranty'
export const API_STATE_GUARANTY_ITEM = '/admin/stateGuaranty/:id'

export const API_ACCOUNT = '/admin/account'
export const API_ACCOUNT_ITEM = '/admin/account/:id'

export const API_STATE_CITIES = '/admin/state/cities'
export const API_STATE_COUNTIES = '/admin/state/counties'
export const API_STATE_MSAS = '/admin/state/msas'
export const API_STATE_MSA = '/admin/state/msa'
export const API_STATE_MSA_DATA = '/admin/state/msa/:rank'
export const API_STATE_CITY = '/admin/state/city'
export const API_STATE_COUNTY = '/admin/state/county'
export const API_STATE_UPDATE_CITY = '/admin/state/updateCity'
export const API_STATE_UPDATE_COUNTY = '/admin/state/updateCounty'
export const API_STATE = '/admin/state/data'

export const API_GET_COMPANIES = '/company'
export const API_GET_COMPANIES_INFO = '/company/info'
export const API_GET_COMPANY_RATESHEETS = '/company/ratesheets'
export const API_CREATE_COMPANY = '/company/create'
export const API_DELETE_COMPANY = '/company/:id'
export const API_UPDATE_COMPANY = '/company/:id'
export const API_GET_COMPANY_HISTORY = '/company/history/:id'
export const API_GENERATE_SECRET_KEY = '/company/generateSecretKey/:id'

export const API_ADD_RATESHEET = '/ratesheet/add/:id'
export const API_GET_RATESHEETS = '/ratesheet/:id'
export const API_UPDATE_RATESHEET = '/ratesheet/update/:id'
export const API_DELETE_RATESHEET = '/ratesheet/delete/:loanId'
export const API_REMOVE_OLD_RATESHEET = '/ratesheet/removeOld'
export const API_GET_LOANIDS = '/ratesheet/loanIDs/:id'

export const API_ADD_PROGRAM = '/ratesheet/addProgram/:id'
export const API_CLONE_PROGRAM = '/ratesheet/cloneProgram/:id'
export const API_UPDATE_PROGRAM_RESTRICTION_LTVLIMITS = '/ratesheet/program/restriction/ltvlimits/:id'
export const API_UPDATE_PROGRAM_RESTRICTION = '/ratesheet/program/restriction/:id'
export const API_UPDATE_PROGRAM = '/ratesheet/program/:id'
export const API_UPDATE_DSCR_OCCUPANCY_LTV_REDUCTION = '/ratesheet/dscr/occupancy/ltvReduction/:loanId'
export const API_UPDATE_PROGRAM_INFO = '/ratesheet/program/info/:id'
export const API_UPDATE_PROGRAMS_ORDER = '/ratesheet/programs-order/:id'
export const API_DELETE_PROGRAM = '/ratesheet/program/:id/:proId'
export const API_PROGRAM_BONUS_MARGIN = '/ratesheet/program/bonusMargin/:loanId'

export const API_SAVE_LOAN_DETAIL = '/loan/save/:id'
export const API_GET_LOAN_DETAILS = '/loan/get'
export const API_LOAN_DETAIL = '/loan/:id'

export const API_RATESHEET_HISTORY = '/ratesheet/history/:loanId'

export const API_UPDATE_PRODUCT_POWER = '/ratesheet/product/:id/:prodId'

export const API_GET_PREPAY_PENALTY = '/ratesheet/prepayPenalty'
export const API_GET_TEMPLATES = '/ratesheet/templates'
export const API_SYNC_PROGRAM = '/ratesheet/syncProgram'
export const API_SYNC_PROGRAM_TO_GENERAL = '/ratesheet/syncProgramToGeneral'
export const API_SYNC_PROGRAM_TIERS = '/ratesheet/syncProgramTiers'

export const API_PRICE_LOAN = '/ratesheet/price-loan'
export const API_FAST_MONEY_PRICE_LOAN = '/ratesheet/fastmoney-price-loan'

export const API_CONVERT_DB_FROM_MONGO = '/dataConversion/fromMongo'
export const API_CONVERT_DB_FROM_PG = '/dataConversion/fromPG'

export const API_UPLOAD_EXCEL_TO_S3 = '/ratesheet/uploadExcelToS3/:loanId'

export const API_STATE_TIER = '/ratesheet/tier/:loanId/:programID'
export const API_ADD_STATE_TIER = '/ratesheet/state/addTier/:loanId'
export const API_REMOVE_STATE_TIER = '/ratesheet/state/removeTier/:loanId'
export const API_UPDATE_STATE_TIER = '/ratesheet/state/updateTier/:loanId'
export const API_SAVE_STATE_TIER_VALUES = '/ratesheet/state/tierValues/:loanId'
export const API_SAVE_TIER_LTV_ADJ = '/ratesheet/state/updateTierLtvAdj/:loanId'
export const API_SAVE_MSA_TIER_LTV_ADJ = '/ratesheet/state/updateMsaTierLtvAdj/:loanId'
export const API_REMOVE_MSA_TIER_LTV_ADJ_CONDITION = '/ratesheet/state/removeMsaTierLtvAdjCondition/:loanId'
export const API_EXPERIENCE_TIER_LIMIT = '/ratesheet/tier/experienceLimit/:loanId'

export const API_UPDATE_RANGE_FOR_DSCR = '/ratesheet/dscrRange/:loanId'
export const API_UPDATE_LTV_RATE_RANGE_FOR_NON_DSCR = '/ratesheet/nonDscr/ltvRatePriceRange/:loanId'
export const API_SAVE_COMMERCIAL_PRODUCT_TYPES = '/ratesheet/nonDscr/productTypes/:loanId'
export const API_NON_DSCR_LEVERAGE_DATA = '/ratesheet/nonDscrLeverageData/:loanId'
export const API_DSCR_LEVERAGE_DATA = '/ratesheet/dscrLeverageData/:loanId'
export const API_GET_NON_DSCR_LEVERAGE_DATA = '/ratesheet/nonDscrLeverageData/:loanId/:programID/:key'
export const API_GET_DSCR_LEVERAGE_DATA = '/ratesheet/dscrLeverageData/:loanId/:programID/:key'

export const API_REHAB_BUDGET_TYPES_DEF = '/definition/rehabBudgetType/:companyID'
export const API_REHAB_BUDGET_TYPES_GENERAL_DEF = '/definition/rehabBudgetType/general'

export const API_MASTER_PROGRAM = '/ratesheet/masterProgram/:loanId'
export const API_MASTER_PROGRAM_ID = '/ratesheet/masterProgram/:loanId/:programID'

export const API_STATIC_TABLE = '/staticTable/table/:loanId/:programID'
export const API_STATIC_TABLE_INDEX = '/staticTable/table/:loanId/:programID/:index'
export const API_STATIC_TABLE_INDEX_ID = '/staticTable/table/:loanId/:programID/:index/:id'
export const API_STATIC_TABLE_INDEX_ORDER = '/staticTable/table/:loanId/:programID/:index/:orgIndex/:newIndex'

/**
 * Exception
 */
export const API_GET_EXCEPTIONS = '/exception/all'
export const API_EXCEPTION_ID = '/exception/:id'
