export const PropertyTypeLLPAOptions: string[] = [
  'SFR',
  'PUD',
  'D-PUD',
  'Townhouse',
  'Rowhouse',
  'Site Condo',
  'Condo-Warrantable',
  'Condo-NonWarrantable',
  'Condotel',
  'Mixed Use',
  'Multifamily',
  'Modular',
  'Industrial',
  'Manufactured',
  'Self Storage',
  'Office',
  'Retail',
  'Warehouse',
  'Automotive',
  'Rural',
]

export const MultifamilyPropertyTypes: string[] = ['Multifamily', 'Mixed Use']
