import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import type { INormalLLPA, IRange } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, Select } from 'stories/components'
import { confirm } from 'utils'

import { convertNullToBlank } from '../CommercialOnlyProgrammed/logic'
import { MultifamilyPropertyTypes, PropertyTypeLLPAOptions } from './constants'

interface IProps {
  name: string
  loading: string
  LLPA: INormalLLPA[]
  LTVs: IRange[]
  onClose: () => void
  onSave: (data: INormalLLPA[]) => void
}

export const PropertyTypeLLPAEditModal = (props: IProps) => {
  const { name, loading, LLPA, LTVs, onClose, onSave } = props

  const [data, setData] = useState<INormalLLPA[]>([])

  useEffect(() => {
    if (LLPA) {
      setData(LLPA)
    } else setData([])
  }, [LLPA])

  const onAdd = () => {
    const newData = cloneDeep(data)

    const newLLPA: number[] = []
    LTVs.forEach(() => newLLPA.push(0))

    newData.push({
      Item: '',
      LLPA: newLLPA,
    })
    setData(newData)
  }

  const onRemove = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this Property Type?
        <br />
        <span className="text-gray-600 text-base">Property Type: {data[index].Item}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    setData(newData)
  }

  const onChangePropertyType = (index: number, value: string) => {
    const newData = cloneDeep(data)
    const isExist = newData.find((item) => item.Item === value)
    if (isExist && !MultifamilyPropertyTypes.includes(value)) return
    newData[index].Item = value
    if (!MultifamilyPropertyTypes.includes(value)) delete newData[index]?.Range
    setData(newData)
  }

  const onChangeNumberOfUnits = (index: number, key: 'from' | 'to', value: any) => {
    if (!value) return
    const newData = cloneDeep(data)
    value = Number(value)
    if (!newData[index]?.Range) newData[index].Range = { from: value, to: value }
    ;(newData[index].Range as IRange)[key] = value
    const { from, to } = newData[index].Range as IRange
    if (from > to) return

    setData(newData)
  }

  const onChangeAdjustment = (index: number, idx: number, value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    newData[index].LLPA[idx] = value

    setData(newData)
  }

  const onOk = () => {
    let errMsg = ''

    data.forEach((item, index) => {
      if (!item.Item) errMsg = 'Property Type is required'
      else if (MultifamilyPropertyTypes.includes(item.Item)) {
        const sameIndex = data.findIndex(
          (value) =>
            value.Item === data[index].Item &&
            value?.Range?.from === data[index].Range?.from &&
            value?.Range?.to === data[index].Range?.to,
        )

        if (sameIndex !== -1 && sameIndex !== index) errMsg = 'The same Property Type exists'
        if (!item.Range?.from || !item.Range.to) errMsg = 'The units range is required'
      }
    })

    if (errMsg) {
      toast(errMsg, { type: 'error' })
      return
    }

    onSave(data)
  }

  const renderTable = useMemo(() => {
    return (
      <table className="table w-full text-center">
        <thead className="bg-gray-100">
          <tr>
            <th rowSpan={2} className="border p-2 whitespace-nowrap">
              Property Type
            </th>
            <th colSpan={2} className="border p-2 whitespace-nowrap">
              # of Units
            </th>
            {LTVs.map((item, index) => (
              <th rowSpan={2} key={index} className="border p-2 w-20 whitespace-nowrap">
                {item.from} - {item.to}
              </th>
            ))}
            <th rowSpan={2} className="border p-2 w-fit">
              Action
            </th>
          </tr>
          <tr>
            <th className="border p-2 w-20">From</th>
            <th className="border p-2 w-20">To</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            let disableUnits = true

            if (MultifamilyPropertyTypes.includes(item.Item)) {
              disableUnits = false
            }

            return (
              <tr key={index}>
                <td className="border p-1">
                  <div className="flex justify-center">
                    <Select
                      id="propertyType"
                      options={PropertyTypeLLPAOptions}
                      value={item.Item}
                      hasDefaultOption={true}
                      className="mb-[-16px] w-48"
                      onChange={(value) => onChangePropertyType(index, value)}
                    />
                  </div>
                </td>
                <td className={`border p-1 ${disableUnits && 'bg-gray-100'}`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item?.Range?.from)}
                      origin={convertNullToBlank(item?.Range?.from)}
                      content={convertNullToBlank(item?.Range?.from)}
                      onChange={(value: any) => onChangeNumberOfUnits(index, 'from', value)}
                      className="w-full"
                      type="number"
                      disabled={disableUnits}
                    />
                  </div>
                </td>
                <td className={`border p-1 ${disableUnits && 'bg-gray-100'}`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item?.Range?.to)}
                      origin={convertNullToBlank(item?.Range?.to)}
                      content={convertNullToBlank(item?.Range?.to)}
                      onChange={(value: any) => onChangeNumberOfUnits(index, 'to', value)}
                      className="w-full"
                      type="number"
                      disabled={disableUnits}
                    />
                  </div>
                </td>

                {item.LLPA.map((v, idx) => (
                  <td key={`${index}-${idx}`} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(v)}
                        origin={convertNullToBlank(v)}
                        content={convertNullToBlank(v)}
                        onChange={(value: any) => onChangeAdjustment(index, idx, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}

                <td className="p-1 border">
                  {item.Item !== 'None of Above' && (
                    <div className=" flex justify-center items-center">
                      <div
                        className="w-fit p-1 hover:cursor-pointer hover-shadow1 transition-all duration-200 rounded"
                        onClick={() => onRemove(index)}
                      >
                        <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }, [data, LTVs])

  return (
    <Modal
      title={`Edit ${name}`}
      titleOkay="Save"
      isOpen={true}
      loading={loading === LoadingStatus.UPDATE_RANGE}
      onClose={onClose}
      onOk={onOk}
    >
      <div>
        {renderTable}

        <div className="flex justify-start mt-4 px-3">
          <div
            className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
            onClick={onAdd}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}
