import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import type { IRange, IRangeLLPA } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { Modal, Select } from 'stories/components'
import { confirm, getPrice3decimal, isEmpty, removeComma } from 'utils'

import { convertNullToBlank } from '../CommercialOnlyProgrammed/logic'

interface IProps {
  name: string
  loading: string
  range: IRangeLLPA[]
  LTVs: IRange[]
  rangeStep?: number
  onClose: () => void
  onSave: (data: IRangeLLPA[]) => void
}

const orderOptions = {
  asc: 'Ascending',
  desc: 'Descending',
}

export const CreditNoScore = 'No Score'

export const RangeEditModal = (props: IProps) => {
  let { range } = props
  const { name, loading, LTVs, rangeStep = 1, onClose, onSave } = props

  const [data, setData] = useState<IRangeLLPA[]>([])
  const [noScore, setNoScore] = useState<IRangeLLPA>()
  const [order, setOrder] = useState(orderOptions.asc)

  useEffect(() => {
    if (range) {
      if ((range[range.length - 1].Item as any) === CreditNoScore) {
        const noScoreItem = range.pop()
        setNoScore(noScoreItem)
      }
      range.forEach((item) => {
        if (isEmpty(item.Item.from)) item.Item.from = 0
      })
      setData(range)
      if (!!range.length && range.length > 1) if (range[0].Item.to > range[1].Item.from) setOrder(orderOptions.desc)
    } else setData([])
  }, [range])

  const onAddRange = () => {
    const newRanges = cloneDeep(data)

    const newLLPA: number[] = []

    LTVs.forEach(() => newLLPA.push(0))

    if (order === orderOptions.desc) newRanges.reverse()

    if (!data.length)
      newRanges.push({
        Item: { from: 0, to: 0 },
        LLPA: newLLPA,
      })
    else if (isNaN(newRanges[newRanges.length - 1].Item.to) || isEmpty(newRanges[newRanges.length - 1].Item.to)) {
      newRanges[newRanges.length - 1].Item.to = newRanges[newRanges.length - 1].Item.from

      newRanges.push({
        Item: {
          from: newRanges[newRanges.length - 1].Item.from + rangeStep,
          to: newRanges[newRanges.length - 1].Item.from + rangeStep,
        },
        LLPA: newLLPA,
      })
    } else
      newRanges.push({
        Item: {
          from: newRanges[newRanges.length - 1].Item.to + rangeStep,
          to: newRanges[newRanges.length - 1].Item.to + rangeStep,
        },
        LLPA: newLLPA,
      })

    if (order === orderOptions.desc) newRanges.reverse()

    setData(newRanges)
  }

  const onChangeRange = (index: number, value: any, startValue = false) => {
    const newRanges = cloneDeep(data)

    value = removeComma(value) === 0 ? NaN : removeComma(value)

    if (order === orderOptions.desc) {
      newRanges.reverse()
      index = newRanges.length - index - 1
    }

    if (startValue) {
      newRanges[0].Item.from = isNaN(value) ? 0 : value

      if (newRanges[0].Item.from > newRanges[0].Item.to) newRanges[0].Item.to = newRanges[0].Item.from
    } else {
      if (isNaN(value) || isEmpty(value)) {
        if (index === newRanges.length - 1) newRanges[index].Item.to = value
        else return
      } else if (newRanges[index].Item.from > value) return
      else newRanges[index].Item.to = value
    }

    for (let i = index + 1; i < newRanges.length; i++) {
      if (newRanges[i].Item.from - newRanges[i - 1].Item.to !== rangeStep)
        newRanges[i].Item.from = newRanges[i - 1].Item.to + rangeStep

      if (
        newRanges[i].Item.from > newRanges[i].Item.to &&
        !isNaN(newRanges[i].Item.to) &&
        !isEmpty(newRanges[i].Item.to)
      )
        newRanges[i].Item.to = newRanges[i].Item.from
    }

    if (order === orderOptions.desc) newRanges.reverse()

    setData(newRanges)
  }

  const onRemove = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this range?
        <br />
        <span className="text-gray-600 text-base">
          {name}: {getPrice3decimal(data[index].Item.from)}{' '}
          {isEmpty(data[index].Item.to) ? '+' : `― ${getPrice3decimal(data[index].Item.to)}`}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    if (order === orderOptions.desc) {
      newData.reverse()
      index = newData.length - index - 1
    }

    newData.splice(index, 1)

    for (let i = 1; i < newData.length; i++) {
      if (newData[i].Item.from - newData[i - 1].Item.to !== rangeStep)
        newData[i].Item.from = newData[i - 1].Item.to + rangeStep

      if (newData[i].Item.from > newData[i].Item.to && !isNaN(newData[i].Item.to) && !isEmpty(newData[i].Item.to))
        newData[i].Item.to = newData[i].Item.from
    }

    if (order === orderOptions.desc) newData.reverse()

    setData(newData)
  }

  const onChangeAdjustment = (index: number, idx: number, value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    newData[index].LLPA[idx] = value

    setData(newData)
  }

  const renderTable = useMemo(() => {
    return (
      <table className="table w-full text-center">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-3 py-2 w-40 whitespace-nowrap">From</th>
            <th className="border px-3 py-2 w-40 whitespace-nowrap">To</th>
            {LTVs.map((item, index) => (
              <th key={index} className="border px-3 py-2 w-20 whitespace-nowrap">
                {item.from} - {item.to}
              </th>
            ))}
            <th className="border px-3 py-2 w-fit">Action</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={getPrice3decimal(item.Item.from)}
                    origin={getPrice3decimal(item.Item.from)}
                    content={getPrice3decimal(item.Item.from)}
                    disabled={order === orderOptions.asc ? index !== 0 : index !== data.length - 1}
                    onChange={(value: any) => onChangeRange(index, value, true)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={getPrice3decimal(item.Item.to)}
                    origin={getPrice3decimal(item.Item.to)}
                    content={getPrice3decimal(item.Item.to)}
                    onChange={(value: any) => onChangeRange(index, value)}
                    className="w-full"
                  />
                </div>
              </td>

              {item.LLPA.map((v, idx) => (
                <td key={`${index}-${idx}`} className="border p-1">
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(v)}
                      origin={convertNullToBlank(v)}
                      content={convertNullToBlank(v)}
                      onChange={(value: any) => onChangeAdjustment(index, idx, value)}
                      className="w-full"
                    />
                  </div>
                </td>
              ))}

              <td className="p-1 border">
                <div className=" flex justify-center items-center">
                  <div
                    className="w-fit p-1 hover:cursor-pointer hover-shadow1 transition-all duration-200 rounded"
                    onClick={() => onRemove(index)}
                  >
                    <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [data, LTVs])

  return (
    <Modal
      title={`Edit ${name}`}
      titleOkay="Save"
      isOpen={true}
      loading={loading === LoadingStatus.UPDATE_RANGE}
      onClose={onClose}
      onOk={() => {
        const newData = cloneDeep(data)
        if (!!noScore) newData.push(noScore)
        onSave(newData)
      }}
    >
      <div>
        <Select
          id="order"
          title="Change Order"
          options={Object.values(orderOptions)}
          value={order}
          className="-mb-2 w-48"
          onChange={(value) => {
            const newData = cloneDeep(data)
            newData.reverse()
            setData(newData)
            setOrder(value)
          }}
        />

        {renderTable}

        <div className="flex justify-start items-center gap-4 mt-4 px-3">
          <div
            className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
            onClick={onAddRange}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add Range</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}
