import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import type { ILoanPurposeLTVValues } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useState } from 'react'
import { Button } from 'stories/components'

import { convertNullValue } from './logic'

interface IProps {
  isTemplate: boolean
  loading: LoadingStatus
  ltvAdjustment: ILoanPurposeLTVValues
  isCommercial: boolean
  isMsa?: boolean
  onChange?: (ltvAdj: ILoanPurposeLTVValues) => void
  onSave: (ltvAdj: ILoanPurposeLTVValues) => void
}

export const TierLtvAdjustment = (props: IProps) => {
  const { isTemplate, ltvAdjustment, isCommercial, loading, isMsa = false, onSave, onChange = () => {} } = props

  const [ltvAdj, setLtvAdj] = useState<ILoanPurposeLTVValues>()
  const [isChangedLtv, setIsChangedLtv] = useState(false)

  useEffect(() => {
    if (!ltvAdjustment) return
    setLtvAdj(ltvAdjustment)
    setIsChangedLtv(false)
  }, [ltvAdjustment])

  const onChangeLtvAdj = (
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp',
    value: string,
  ) => {
    if (!ltvAdj) return
    let newValue

    if (value === '') newValue = NaN
    else newValue = isNaN(Number(value)) ? NaN : Number(value)

    const newLtvAdjustment = cloneDeep(ltvAdj)

    if (newLtvAdjustment[loanPurpose][key] == newValue) return

    newLtvAdjustment[loanPurpose][key] = newValue

    setIsChangedLtv(true)
    setLtvAdj(newLtvAdjustment)
    onChange(newLtvAdjustment)
  }

  return (
    <div>
      <div className="flex justify-end items-end mb-2">
        {isChangedLtv && !isMsa && (
          <Button
            loading={loading === LoadingStatus.TIER_VALUES}
            className="text-[14px] pt-1.5 pb-1.5 mb-[-1px]"
            onClick={() => {
              if (!ltvAdj) return
              onSave(ltvAdj)
              setIsChangedLtv(false)
            }}
          >
            Save
          </Button>
        )}
      </div>

      {ltvAdj && (
        <table className="table text-sm w-full text-center">
          <thead className="font-bold bg-gray-100 text-center">
            <tr>
              <th colSpan={5} className="p-3 border">
                {isCommercial ? 'LTV Adjustment' : 'LTV Limit and Adjustment'}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-2 border">Loan Purpose</th>
              <th className={`px-3 py-2 border ${isCommercial ? 'w-1/5' : 'w-1/3'}`}>
                {isCommercial ? 'AIV-LTV' : 'LTV Adjustment'}
              </th>
              <th className={`px-3 py-2 border ${isCommercial ? 'w-1/5' : 'w-1/3'}`}>
                {isCommercial ? 'ARV-LTV' : 'Max LTV'}
              </th>
              {isCommercial && (
                <>
                  <th className="px-3 py-2 border w-1/5">LTC</th>
                  <th className="px-3 py-2 border w-1/5">LTP</th>
                </>
              )}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="border p-1">Purchase</td>

              {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => {
                if (!isCommercial && ['ltc', 'ltp'].includes(key)) return <></>
                return (
                  <td key={idx} className="px-3 py-1 border">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue(ltvAdj.purchase[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        origin={convertNullValue(ltvAdj.purchase[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        content={convertNullValue(ltvAdj.purchase[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        disabled={!isTemplate}
                        className="w-full text-center"
                        onChange={(value: string) =>
                          onChangeLtvAdj('purchase', key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp', value)
                        }
                      />
                    </div>
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className="border p-1">No-Cashout</td>

              {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => {
                if (!isCommercial && ['ltc', 'ltp'].includes(key)) return <></>
                return (
                  <td key={idx} className="px-3 py-1 border">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue(ltvAdj.nocashout[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        origin={convertNullValue(ltvAdj.nocashout[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        content={convertNullValue(ltvAdj.nocashout[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        disabled={!isTemplate}
                        className="w-full text-center"
                        onChange={(value: string) =>
                          onChangeLtvAdj('nocashout', key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp', value)
                        }
                      />
                    </div>
                  </td>
                )
              })}
            </tr>

            <tr>
              <td className="border p-1">Cashout</td>

              {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => {
                if (!isCommercial && ['ltc', 'ltp'].includes(key)) return <></>
                return (
                  <td key={idx} className="px-3 py-1 border">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue(ltvAdj.cashout[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        origin={convertNullValue(ltvAdj.cashout[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        content={convertNullValue(ltvAdj.cashout[key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp'])}
                        disabled={!isTemplate}
                        className="w-full text-center"
                        onChange={(value: string) =>
                          onChangeLtvAdj('cashout', key as 'aivLtv' | 'arvLtv' | 'ltc' | 'ltp', value)
                        }
                      />
                    </div>
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}
