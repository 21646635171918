import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

import { Button } from '../Button/Button'
import { TextArea } from '../TextArea/TextArea'

type Function = () => void

interface PromptModalProps {
  /**
   * Title
   */
  content?: string | JSX.Element
  /**
   * Okay button handler
   */
  onOk?: (arg: string) => void
  /**
   * Cancel button handler
   */
  onCancel?: Function
  /**
   * Is Open
   */
  isOpen?: boolean
  lastUpdatedAt: number
  options?: Record<string, any>
}

/**
 * Primary UI component for user interaction
 */
export const PromptModal = ({
  content = '',
  onOk = () => {},
  onCancel = () => {},
  isOpen: parentIsOpen = false,
  lastUpdatedAt,
  options = {},
}: PromptModalProps) => {
  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState(options.value || '')

  useEffect(() => {
    setShowModal(parentIsOpen)
  }, [lastUpdatedAt])

  if (!showModal) return null

  return (
    <>
      <div
        tabIndex={-1}
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full justify-center items-center flex"
      >
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={onCancel}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
            <div className="p-6 text-center">
              <InformationCircleIcon className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" />
              {typeof content === 'string' ? (
                <h3 className="mb-5 text-lg font-normal text-gray-800">{content}</h3>
              ) : (
                content
              )}
              <TextArea
                rows={4}
                autoFocus
                value={value}
                onChange={(v) => setValue(v)}
                placeholder={options.placeholder || ''}
                className="mb-4"
              />

              <Button
                onClick={() => onOk(value.trim())}
                className="py-[6px] min-w-[120px]"
                disabled={options.required && value.trim().length == 0}
              >
                {options.titleOk || 'Ok'}
              </Button>
              <Button onClick={onCancel} className="py-[6px]" color="white">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
    </>
  )
}
