import cloneDeep from 'clone-deep'
import type { ILoanPurposeLTVValues, IPropertyTypeLtvAdj, IStateMSA, IStateTier } from 'config'
import { CityCountyTiers } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/CityCountyTiers'
import { MsaTiers } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/MsaTiers/MsaTiers'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import type { LoadingStatus } from '..'

interface IProps {
  isTemplate: boolean
  title: string
  tiersData: IStateTier
  loading: LoadingStatus
  isGeneralRatesheet: boolean
  isCommercial: boolean
  onAddTier: (tierName: string, key: string) => Promise<boolean>
  onRemoveTier: (tierName: string, key: string) => Promise<boolean>
  onUpdateTier: (originalName: string, tierName: string, key: string) => Promise<boolean>
  onSaveTierValues: (tierName: string, key: string, state: string, values: string[] | IStateMSA[]) => Promise<boolean>
  onSaveLtvAdj: (tierName: string, key: string, ltvAdj: ILoanPurposeLTVValues) => Promise<boolean>
  onSaveMsaLtvAdj: (tierName: string, ltvAdj: IPropertyTypeLtvAdj[]) => Promise<boolean>
  onRemoveMsaLtvAdjCondition: (tierName: string, index: number) => void
  onSyncTiers: (modalName: string) => void
}

export const initialMenus = { cityTier: 'City Tiers', countyTier: 'County Tiers', msaTier: 'MSA Tiers' }

export function StateLTVMaxLimit(props: IProps) {
  const {
    isTemplate,
    title,
    tiersData,
    loading,
    isCommercial,
    onAddTier,
    onRemoveTier,
    onSaveLtvAdj,
    onSaveMsaLtvAdj,
    onUpdateTier,
    onSaveTierValues,
    onRemoveMsaLtvAdjCondition,
  } = props

  const [menus, setMenus] = useState(initialMenus)
  const [tierType, setTierType] = useState(menus.cityTier)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')

  useEffect(() => {
    if (!tiersData) {
      setMenus(initialMenus)
      return
    }

    const { cityTiers, countyTiers, msaTiers } = tiersData

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'cityTier':
          if (cityTiers && !!cityTiers.length) {
            newMenus[key] = initialMenus[key] + ` (${cityTiers.length})`
          }
          break
        case 'countyTier':
          if (countyTiers && !!countyTiers.length) {
            newMenus[key] = initialMenus[key] + ` (${countyTiers.length})`
          }
          break
        case 'msaTier':
          if (msaTiers && !!msaTiers.length) {
            newMenus[key] = initialMenus[key] + ` (${msaTiers.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setTierType((newMenus as any)[selectedMenuKey])
    else setTierType(newMenus.cityTier)
  }, [tiersData])

  const renderTiers = () => {
    switch (tierType) {
      case menus.cityTier:
        return (
          <CityCountyTiers
            isTemplate={isTemplate}
            title={tierType}
            loading={loading}
            isCommercial={isCommercial}
            tiers={tiersData.cityTiers || []}
            onAdd={onAddTier}
            onRemove={onRemoveTier}
            onUpdate={onUpdateTier}
            onSaveTierValues={onSaveTierValues}
            onSaveLtvAdj={onSaveLtvAdj}
          />
        )
      case menus.countyTier:
        return (
          <CityCountyTiers
            isTemplate={isTemplate}
            title={tierType}
            loading={loading}
            isCommercial={isCommercial}
            tiers={tiersData.countyTiers || []}
            onAdd={onAddTier}
            onRemove={onRemoveTier}
            onUpdate={onUpdateTier}
            onSaveTierValues={onSaveTierValues}
            onSaveLtvAdj={onSaveLtvAdj}
          />
        )
      case menus.msaTier:
        return (
          <MsaTiers
            isTemplate={isTemplate}
            loading={loading}
            tiers={tiersData.msaTiers || []}
            isCommercial={isCommercial}
            onAdd={onAddTier}
            onRemove={onRemoveTier}
            onUpdateTier={onUpdateTier}
            onSaveTierValues={onSaveTierValues}
            onSaveLtvAdj={onSaveMsaLtvAdj}
            onRemoveLtvAdjCondition={onRemoveMsaLtvAdjCondition}
          />
        )
      default:
        return <></>
    }
  }

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setTierType(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={tierType} onChange={onChangeMenuItem} />
  }, [menus, tierType])

  return (
    <div>
      <p className="text-lg font-variation-settings-600 mb-2">{title}</p>

      {renderMenus}

      <div className="p-3">{renderTiers()}</div>
    </div>
  )
}
