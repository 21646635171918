import { BarsArrowDownIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { Tooltip } from 'flowbite-react'
import { Fragment, useEffect, useState } from 'react'
import { deleteException, getExceptions, removeMultipleExceptions } from 'services/apis'
import { Checkbox, Input2, Pagination, Select2 } from 'stories/components'
import { confirm, formatTime, renderHeader, useTitle } from 'utils'

import { HttpMethodOptions, IException } from './constants'
import { ExceptionDetails } from './ExceptionDetails'

export const ManageExceptions = () => {
  useTitle('Exception Management')

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    method: '',
    orderBy: 'createdAt',
    orderDir: '-1',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [exceptions, setExceptions] = useState<IException[]>([])
  const [activeException, setActiveException] = useState<IException>()
  const [selectedExceptions, setSelectedExceptions] = useState<number[]>([])
  const [isGetDataOnce, setIsGetDataOnce] = useState(false)
  const [allIDs, setAllIDs] = useState<number[]>([])

  useEffect(() => {
    window.scrollTo(window.scrollX, 0)
    filterData(filters).then(() => {
      setIsGetDataOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetDataOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum
    setLoading(true)
    if (!filters.method) delete filters.method

    return getExceptions({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total, totalIDs }) => {
        setTotal(total)
        setExceptions(data)
        setAllIDs(totalIDs)
      })
      .finally(() => setLoading(false))
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (key: 'query' | 'method' | 'orderBy' | 'orderDir' | 'pageNum', value: any, refetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const onRemove = async (exception: IException) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this exception?
        <br />
        <p className="text-gray-600">User Name: {exception.name || 'Guest'}</p>
        {exception?.company && <p className="text-gray-600">Company: {exception.company}</p>}
      </div>
    )

    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    deleteException(exception.id)
      .then(() => {
        filterData(filters)
      })
      .finally(() => setLoading(false))
  }

  const onSelectAll = (value: boolean) => {
    if (!value) {
      setSelectedExceptions([])
    } else {
      setSelectedExceptions(allIDs)
    }
  }

  const onRemoveSelected = async () => {
    const content = (
      <div className="text-gray-600 mb-4 text-[18px]">
        Do you want to delete the selected exceptions?
        <br />
        <span className="text-gray-500 text-base">Selected {selectedExceptions.length} Exceptions</span>
      </div>
    )

    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    removeMultipleExceptions(selectedExceptions)
      .then(() => {
        onChangeFilter('pageNum', 0)
        setSelectedExceptions([])
      })
      .finally(() => setLoading(false))
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
    })
  }

  return (
    <div className="relative py-6 px-2">
      <LayoutLoading show={isLoading} />

      <div className="shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 w-full">
        <p className="text-2xl font-variation-settings-600 mb-4 flex gap-4 items-center">
          Exception Management ({total})
        </p>

        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
          <Input2
            type="search"
            title="Search"
            hasIcon
            icon={<MagnifyingGlassIcon className="w-4 h-4" />}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
          <Select2
            id="Method"
            title="Method"
            options={HttpMethodOptions}
            value={filters.method}
            hasDefaultOption
            defaultOptionText="All"
            onChange={(value) => onChangeFilter('method', value)}
          />
        </div>

        {!!selectedExceptions.length && (
          <div className="flex justify-between items-center pt-2">
            <p className="text-gray-500 text-sm">Selected {selectedExceptions.length} Exceptions</p>

            <Tooltip content={`Remove Selected Exceptions`}>
              <div
                className={`font-bold text-red-600 transition-all duration-200 rounded cursor-pointer p-1 shadow hover-shadow1`}
                onClick={onRemoveSelected}
              >
                <TrashIcon className="w-4 h-4" />
              </div>
            </Tooltip>
          </div>
        )}

        <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <div className="py-3 px-2">
                  <Checkbox
                    id="allApprove"
                    title="All"
                    value={selectedExceptions.length === total}
                    onChange={onSelectAll}
                  />
                </div>

                {_renderHeader('No', false, 'no')}
                {_renderHeader('Name', true, 'name')}
                {_renderHeader('Company', true, 'company')}
                {_renderHeader('IP Address', true, 'requestIp')}
                {_renderHeader('Method', true, 'method')}
                {_renderHeader('Path', true, 'path')}
                {_renderHeader('Message', true, 'message')}
                {_renderHeader('Created At', true, 'createdAt')}
                {_renderHeader('Actions', false, 'action')}
              </tr>
            </thead>
            <tbody className="text-[14px]">
              {exceptions.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <tr className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                      <td className="pl-6">
                        <Checkbox
                          id={item.id.toString()}
                          value={selectedExceptions.includes(item.id)}
                          onChange={() => {
                            setSelectedExceptions((prevSelected) =>
                              prevSelected.includes(item.id)
                                ? prevSelected.filter((rowId) => rowId !== item.id)
                                : [...prevSelected, item.id],
                            )
                          }}
                        />
                      </td>
                      <td className="pl-3 py-3">{filters.pageNum * itemCountPerPage + index + 1}</td>
                      <td className="px-2 py-2">
                        <div className="font-bold text-indigo-500 cursor-pointer whitespace-nowrap hover:underline">
                          {item?.name || 'Guest'}
                        </div>
                      </td>
                      <td className="px-2 py-2">
                        <div className="font-bold text-indigo-500 cursor-pointer whitespace-nowrap hover:underline">
                          {item?.company || ''}
                        </div>
                      </td>
                      <td className="px-2 py-2">{item.requestIp}</td>
                      <td className="px-2 py-2">{item.method}</td>
                      <td className="px-2 py-2">{item.path}</td>
                      <td className="px-2 py-2 whitespace-pre-wrap">{item.message}</td>
                      <td className="px-2 py-2 whitespace-nowrap">{formatTime(item.createdAt)}</td>
                      <td className="px-2 py-2 w-28">
                        <div className="flex items-center gap-1">
                          <Tooltip content={`Show Details`}>
                            <BarsArrowDownIcon
                              className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200"
                              onClick={() => setActiveException(item)}
                            />
                          </Tooltip>

                          <Tooltip content={`Remove Exception`}>
                            <TrashIcon
                              className="w-6 h-6 cursor-pointer text-red-600 hover-shadow1 p-1 transition-all duration-200"
                              onClick={() => onRemove(item)}
                            />
                          </Tooltip>
                        </div>
                      </td>
                    </tr>

                    {activeException?.id === item.id && (
                      <tr>
                        <td></td>
                        <td colSpan={8}>
                          <ExceptionDetails exception={activeException} onClose={() => setActiveException(undefined)} />
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end items-center mt-3">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={filters.pageNum}
          />
        </div>
      </div>
    </div>
  )
}
