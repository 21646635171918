import { InputType } from 'config'

export interface IException {
  id: number
  statusCode: number
  message: string
  stack: string
  reqBody: string
  requestIp: string
  method: string
  path: string
  createdAt: Date
  updatedAt: Date

  company?: string

  name?: string
  email?: string
  userId?: number
}

export const HttpMethodOptions: string[] = [
  'GET',
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
  'HEAD',
  'OPTIONS',
  'CONNECT',
  'TRACE',
]

export const defaultInputs = (): Record<string, InputType> => {
  return {
    statusCode: {
      title: 'Status Code',
      inputType: 'text',
      disabled: true,
    },
    requestIp: {
      title: 'IP Address',
      inputType: 'text',
      disabled: true,
    },
    stack: {
      title: 'Stack',
      inputType: 'textarea',
      disabled: true,
      rows: 10,
      span: 2,
    },
    reqBody: {
      title: 'Request Body',
      inputType: 'textarea',
      disabled: true,
      rows: 3,
      span: 2,
    },
  }
}
