import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import type { INormalLLPA, IRange } from 'config'
import { statesConstant } from 'config/states.constants'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, Select } from 'stories/components'
import { confirm } from 'utils'

import { convertNullToBlank } from '../CommercialOnlyProgrammed/logic'

interface IProps {
  name: string
  loading: string
  stateLLPA: INormalLLPA[]
  LTVs: IRange[]
  onClose: () => void
  onSave: (data: INormalLLPA[]) => void
}

export const StateLLPAEditModal = (props: IProps) => {
  const { name, loading, stateLLPA, LTVs, onClose, onSave } = props

  const [data, setData] = useState<INormalLLPA[]>([])
  const [stateOptions, setStateOptions] = useState<string[]>([])

  useEffect(() => {
    const newOptions = cloneDeep(statesConstant)
    if (!newOptions.includes('NY (CEMA)')) {
      newOptions.push('NY (CEMA)')
      newOptions.sort()
      setStateOptions(newOptions)
    }
  }, [statesConstant])

  useEffect(() => {
    if (stateLLPA) {
      setData(stateLLPA)
    } else setData([])
  }, [stateLLPA])

  const onAdd = () => {
    const newData = cloneDeep(data)

    const newLLPA: number[] = []

    LTVs.forEach(() => newLLPA.push(0))

    const nonLLPA = newData.pop()

    if (nonLLPA) {
      newData.push({
        Item: '',
        LLPA: newLLPA,
      })

      newData.push(nonLLPA)

      setData(newData)
    }
  }

  const onRemove = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this state?
        <br />
        <span className="text-gray-600 text-base">State: {data[index].Item}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    setData(newData)
  }

  const onChangeState = (index: number, value: string) => {
    const newData = cloneDeep(data)

    const isExist = newData.find((item) => item.Item === value)

    if (isExist) return

    newData[index].Item = value

    setData(newData)
  }

  const onChangeAdjustment = (index: number, idx: number, value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    newData[index].LLPA[idx] = value

    setData(newData)
  }

  const onOk = () => {
    const newData = cloneDeep(data)

    let hasError = false

    newData.forEach((item) => {
      if (!item.Item) hasError = true
    })

    if (hasError) {
      toast(`State is required`, { type: 'error' })
      return
    }

    const nonLLPA = newData.pop()

    if (nonLLPA) {
      newData.sort((x, y) => {
        const a = x.Item.toLowerCase()
        const b = y.Item.toLowerCase()

        if (a < b) return -1

        if (a > b) return 1

        return 0
      })

      newData.push(nonLLPA)

      onSave(newData)
    }
  }

  const renderTable = useMemo(() => {
    return (
      <table className="table w-full text-center">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-3 py-2 w-40 whitespace-nowrap">State</th>
            {LTVs.map((item, index) => (
              <th key={index} className="border px-3 py-2 w-20 whitespace-nowrap">
                {item.from} - {item.to}
              </th>
            ))}
            <th className="border px-3 py-2 w-fit">Action</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="border p-1">
                <div className="flex justify-center">
                  {item.Item !== 'None of Above' ? (
                    <Select
                      id="state"
                      options={stateOptions}
                      value={item.Item}
                      hasDefaultOption={true}
                      className="mb-[-16px] w-[120px]"
                      onChange={(value) => onChangeState(index, value)}
                    />
                  ) : (
                    item.Item
                  )}
                </div>
              </td>

              {item.LLPA.map((v, idx) => (
                <td key={`${index}-${idx}`} className="border p-1">
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(v)}
                      origin={convertNullToBlank(v)}
                      content={convertNullToBlank(v)}
                      onChange={(value: any) => onChangeAdjustment(index, idx, value)}
                      className="w-full"
                    />
                  </div>
                </td>
              ))}

              <td className="p-1 border">
                {item.Item !== 'None of Above' && (
                  <div className=" flex justify-center items-center">
                    <div
                      className="w-fit p-1 hover:cursor-pointer hover-shadow1 transition-all duration-200 rounded"
                      onClick={() => onRemove(index)}
                    >
                      <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [data, LTVs])

  return (
    <Modal
      title={`Edit ${name}`}
      titleOkay="Save"
      isOpen={true}
      loading={loading === LoadingStatus.UPDATE_RANGE}
      onClose={onClose}
      onOk={onOk}
    >
      <div>
        {renderTable}

        <div className="flex justify-start mt-4 px-3">
          <div
            className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
            onClick={onAdd}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add New State</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}
