import cloneDeep from 'clone-deep'
import {
  type ILeverageMaxLimits,
  type IProgram,
  bankruptcyOptions,
  commercialOccupancyTypeOptions,
  forbearanceOptions,
  foreclosuresOptions,
  mortgageLatesOptions,
  yesNoOptions,
} from 'config'
import { useEffect, useMemo, useState } from 'react'
import { getNonDscrLeverageData } from 'services'
import { ButtonGroup } from 'stories/components'

import { CitizenshipLtvMaxLimits } from './CitizenshipLtvMaxLimits'
import { CrossCollateralBlanketLimit } from './CrossCollateralBlanketLimit'
import { ExperienceLtvMaxLimits } from './ExperienceLtvMaxLimits'
import { FicoLeverageLimit } from './FicoLeverageLimit'
import { FirstTimeInvestorLeverageMaxLimits } from './FirstTimeInvestorLeverageMaxLimits'
import { LoanAmountLeverageLimit } from './LoanAmountLeverageLimit'
import { MaxCashToBorrowerMaxLimits } from './MaxCashToBorrowerMaxLimits'
import { PropertyTypeLtvMaxLimits } from './PropertyTypeLtvMaxLimits'
import { RehabBudgetLtvmaxLimits } from './RehabBudgetLtvmaxLimits'
import { RuralPropertyLeverageMaxLimit } from './RuralPropertyLeverageMaxLimit'
import { SecondLienMaxLimits } from './SecondLienMaxLimits'
import { ValueLeverageLimit } from './ValueLeverageLimit'

export const LoanPurposeLabels: Record<string, string> = {
  purchase: 'Purchase',
  nocashout: 'No-Cashout',
  cashout: 'Cashout',
}

interface IProps {
  program: IProgram
  loanId: number
  title: string
  isTemplate: boolean
  loading: string
  onChangeNonDscrLeverageData: (data: any, limitKey: string, type: string) => void
}

const initialMenus = {
  occupancy: 'Occupancy',
  loanAmount: 'Loan Amount',
  experience: 'Experience',
  propertyType: 'Property Type',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  citizenship: 'Citizenship',
  fico: 'FICO',
  rehabBudgetType: 'Rehab Budget Type',
  decliningMarketProperty: 'Property in a declining market?',
  newConstructionProperty: 'Is the property a new construction?',
  firstTimeHomeBuyer: 'First Time Home Buyer?',
  ruralProperty: 'Rural Property?',
  firstTimeInvestor: 'First Time Investor?',
  maxCashToBorrower: 'Cash in Hand',
  crossCollateral: 'Cross Collateral / Blanket (6)',
  secondLien: 'Second Lien (2)',
}

export const LeverageLimits = (props: IProps) => {
  const { title, isTemplate, loading, loanId, program, onChangeNonDscrLeverageData } = props

  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [leverageData, setLeverageData] = useState<ILeverageMaxLimits>()

  useEffect(() => {
    if (isTemplate) setLeverageData(program.OtherTypeProgramData.leverageMaxLimits)
    else {
      ;(async () => {
        setLoading(true)

        try {
          const res = await getNonDscrLeverageData(loanId, program.ID, 'leverageMaxLimits')

          setLeverageData(res)
        } catch (err) {
          console.log(err)
        }

        setLoading(false)
      })()
    }
  }, [program])

  useEffect(() => {
    if (!leverageData) {
      setMenus(initialMenus)
      return
    }

    const {
      experienceLtvLimits,
      propertyTypeLtvLimits,
      occupancyLeverageLimit,
      mortgageLatesLtvLimits,
      fcSsDilLeverageMaxLimit,
      bankruptcyLeverageMaxLimit,
      citizenshipLtvLimits,
      rehabBudgetTypeLtvLimits,
      ficoLeverageLimit,
      firstTimeInvestorLeverageMaxLimit,
      ruralPropertyLeverageMaxLimit,
      maxCashToBorrowerLeverageMaxLimit,
      loanAmountLeverageLimit,
      forbearanceLeverageLimit,
      decliningMarketPropertyLeverageLimit,
      newConstructionPropertyLeverageLimit,
      firstTimeHomeBuyerLeverageLimit,
    } = leverageData as ILeverageMaxLimits

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'firstTimeHomeBuyer':
          if (!!firstTimeHomeBuyerLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${firstTimeHomeBuyerLeverageLimit.length})`
          }
          break
        case 'newConstructionProperty':
          if (!!newConstructionPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${newConstructionPropertyLeverageLimit.length})`
          }
          break
        case 'decliningMarketProperty':
          if (!!decliningMarketPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${decliningMarketPropertyLeverageLimit.length})`
          }
          break
        case 'occupancy':
          if (!!occupancyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${occupancyLeverageLimit.length})`
          }
          break
        case 'experience':
          if (!!experienceLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${experienceLtvLimits.length})`
          }
          break
        case 'loanAmount':
          if (!!loanAmountLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${loanAmountLeverageLimit.length})`
          }
          break
        case 'propertyType':
          if (!!propertyTypeLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${propertyTypeLtvLimits.length})`
          }
          break
        case 'mortgageLates':
          if (!!mortgageLatesLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${mortgageLatesLtvLimits.length})`
          }
          break
        case 'forbearance':
          if (!!forbearanceLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${forbearanceLeverageLimit.length})`
          }
          break
        case 'fcSsDil':
          if (!!fcSsDilLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${fcSsDilLeverageMaxLimit.length})`
          }
          break
        case 'bankruptcy':
          if (!!bankruptcyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${bankruptcyLeverageMaxLimit.length})`
          }
          break
        case 'citizenship':
          if (!!citizenshipLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${citizenshipLtvLimits.length})`
          }
          break
        case 'rehabBudgetType':
          if (!!rehabBudgetTypeLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${rehabBudgetTypeLtvLimits.length})`
          }
          break
        case 'fico':
          if (!!ficoLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${ficoLeverageLimit.length})`
          }
          break
        case 'firstTimeInvestor':
          if (!!firstTimeInvestorLeverageMaxLimit.length && !!firstTimeInvestorLeverageMaxLimit[0].limits.length) {
            newMenus[key] = initialMenus[key] + ` (${firstTimeInvestorLeverageMaxLimit[0].limits.length})`
          }
          break
        case 'ruralProperty':
          if (!!ruralPropertyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${ruralPropertyLeverageMaxLimit.length})`
          }
          break
        case 'maxCashToBorrower':
          if (!!maxCashToBorrowerLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${maxCashToBorrowerLeverageMaxLimit.length})`
          }
          break
        case 'maxCashToBorrower':
          if (!!maxCashToBorrowerLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${maxCashToBorrowerLeverageMaxLimit.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [leverageData, program])

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.experience:
        return (
          <ExperienceLtvMaxLimits
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            program={program}
            isLoading={isLoading}
            leverageData={leverageData?.experienceLtvLimits || []}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.propertyType:
        return (
          <PropertyTypeLtvMaxLimits
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            program={program}
            isLoading={isLoading}
            leverageData={leverageData?.propertyTypeLtvLimits || []}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.rehabBudgetType:
        return (
          <RehabBudgetLtvmaxLimits
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.rehabBudgetTypeLtvLimits || []}
            program={program}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.citizenship:
        return (
          <CitizenshipLtvMaxLimits
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.citizenshipLtvLimits || []}
            program={program}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.occupancy:
        return (
          <ValueLeverageLimit
            isTemplate={isTemplate}
            title="Occupancy"
            options={commercialOccupancyTypeOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.occupancyLeverageLimit || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'occupancyLeverageLimit', 'leverageMaxLimits')}
          />
        )
      case menus.decliningMarketProperty:
        return (
          <ValueLeverageLimit
            isTemplate={isTemplate}
            title="Property in a declining market?"
            options={yesNoOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.decliningMarketPropertyLeverageLimit || []}
            program={program}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'decliningMarketPropertyLeverageLimit', 'leverageMaxLimits')
            }
          />
        )
      case menus.newConstructionProperty:
        return (
          <ValueLeverageLimit
            isTemplate={isTemplate}
            title="Is the property a new construction?"
            options={yesNoOptions}
            loading={loading}
            isLoading={isLoading}
            onlyBridge={true}
            leverageData={leverageData?.newConstructionPropertyLeverageLimit || []}
            program={program}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'newConstructionPropertyLeverageLimit', 'leverageMaxLimits')
            }
          />
        )
      case menus.firstTimeHomeBuyer:
        return (
          <ValueLeverageLimit
            isTemplate={isTemplate}
            title="First Time Home Buyer?"
            options={yesNoOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.firstTimeHomeBuyerLeverageLimit || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'firstTimeHomeBuyerLeverageLimit', 'leverageMaxLimits')}
          />
        )
      case menus.mortgageLates:
        return (
          <ValueLeverageLimit
            isTemplate={isTemplate}
            title="Mortgage Lates"
            options={mortgageLatesOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.mortgageLatesLtvLimits || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'mortgageLatesLtvLimits', 'leverageMaxLimits')}
          />
        )
      case menus.fcSsDil:
        return (
          <ValueLeverageLimit
            options={foreclosuresOptions}
            isTemplate={isTemplate}
            title="FC/SS/DIL"
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.fcSsDilLeverageMaxLimit || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'fcSsDilLeverageMaxLimit', 'leverageMaxLimits')}
          />
        )
      case menus.bankruptcy:
        return (
          <ValueLeverageLimit
            isTemplate={isTemplate}
            title="Bankruptcy"
            options={bankruptcyOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.bankruptcyLeverageMaxLimit || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'bankruptcyLeverageMaxLimit', 'leverageMaxLimits')}
          />
        )
      case menus.forbearance:
        return (
          <ValueLeverageLimit
            isTemplate={isTemplate}
            title="Forbearance / Loan Modification"
            options={forbearanceOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.forbearanceLeverageLimit || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'forbearanceLeverageLimit', 'leverageMaxLimits')}
          />
        )
      case menus.firstTimeInvestor:
        return (
          <FirstTimeInvestorLeverageMaxLimits
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.firstTimeInvestorLeverageMaxLimit || []}
            program={program}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.ruralProperty:
        return (
          <RuralPropertyLeverageMaxLimit
            isTemplate={isTemplate}
            title={selectedMenu}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.ruralPropertyLeverageMaxLimit || []}
            program={program}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.maxCashToBorrower:
        return (
          <MaxCashToBorrowerMaxLimits
            isTemplate={isTemplate}
            isDSCR={false}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.maxCashToBorrowerLeverageMaxLimit || []}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.crossCollateral:
        return (
          <CrossCollateralBlanketLimit
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.crossCollateralBlanket}
            onSave={(data, key) => onChangeNonDscrLeverageData(data, key, 'leverageMaxLimits')}
          />
        )
      case menus.secondLien:
        return (
          <SecondLienMaxLimits
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.secondLienMaxLimit}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'secondLienMaxLimit', 'leverageMaxLimits')}
          />
        )
      case menus.fico:
        return (
          <FicoLeverageLimit
            isTemplate={isTemplate}
            loading={loading}
            program={program}
            isLoading={isLoading}
            leverageData={leverageData?.ficoLeverageLimit || []}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'ficoLeverageLimit', 'leverageMaxLimits')}
          />
        )
      case menus.loanAmount:
        return (
          <LoanAmountLeverageLimit
            isTemplate={isTemplate}
            loading={loading}
            program={program}
            isLoading={isLoading}
            leverageData={leverageData?.loanAmountLeverageLimit || []}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'loanAmountLeverageLimit', 'leverageMaxLimits')}
          />
        )
      default:
        return <></>
    }
  }, [selectedMenu, loading, program, isLoading, leverageData])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div>
      <div className="text-lg font-variation-settings-600 mb-3">{title}</div>

      {renderMenus}

      <div className="py-2">{renderContent}</div>
    </div>
  )
}
